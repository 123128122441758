body {
	background: linear-gradient(90deg, #F7F7F7, #FFF);
}

.header{ 
    width: 100%;
    background-color: #282A6F;
    height: 50px;
    border-bottom: 3px solid #D96D38;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    z-index: 100;
}
.FechaInicial .MuiInputBase-root{
    height: 43px;
    overflow: hidden;
}
.infoUser{
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    background-color: #F0F0F0;
    justify-content: center;
}
.infoUserList{
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #F0F0F0;
    display: flex;
    justify-content: center;
}
.topMargin{
    margin-top: 50px;
}
#terms .MuiFormControlLabel-label{
    font-size: 12px;
}
.infoDetailPackage{
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    position: sticky;
}
.infoDetailPackage1{
    width: 100%;
    padding-top: 20px;
    background: #282a6f;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    font-weight: bold;
    justify-content: center;
    position: sticky;
}
.contentBox{
    max-width: 1300px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 290px));
    grid-gap: 10px;
}
.box {
    background-color: #FFF;
    padding: 20px;
    height: 212px;
    border-radius: 20px;
}
.titleBox{
    font-size: 14px;
    font-weight: 500;
    padding: 6px;
    color: #707070;
    width: calc(100% - 12px);
    background-color: #F9F9F9;
    border-radius: 0px 0px 10px 10px;
    
}
.circleIcon{
    background-color: #F8F8F8;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-top: 10px;
}
.circleIconMin{
    background-color: #F8F8F8;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 10px;
}
.circleIconBalance{
    background-color: #F8F8F8;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-top: 10px;
}
.circleIconBalanceMin{
    background-color: #F8F8F8;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 10px;
}
.iconImage{
    padding: 20%;
    width: 60%;
}
.textItemBox{
    font-size: 30px;
    font-weight: bold;
    padding: 6px;
    color: #707070;
}
.itemListLeft{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 20px;
}
.MuiAccordionSummary-content{
    margin: 0px !important;
}
.MuiAccordionDetails-root{
    padding: 5px 0px !important;
}
.itemListLeftPackage{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    gap:10px
}
.infoPeso{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 20px;
    font-size: 16px;
    align-items: end;
}
.itemList{
    background-color: #fff;
    height: 120px;
    border-radius: 20px;
    border: 1px solid #C9C9C9;
    display: grid;
    background-color: #fff;
    height: 120px;
    font-size: 14px;
    border-radius: 20px;
    border: 1px solid #e4e4e4;
    display: grid;
    grid-template-columns: 25px 1fr 150px;
  }
  .itemListPackage{
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid #e4e4e4;
    display: grid;
  }
  .leftColor{
    background-color: #F9F9F9;
    border-radius: 20px 0 0 20px;
  }
  /* Media query para dispositivos móviles */
@media (max-width: 500px) {
    .itemList {
    grid-template-columns: 25px 1fr 80px;
    }
}
.numPackage{
    color:#7f7f7f
}
.TitleBalance{
    color: #5d5d5d;
    margin: 0px;
    margin-bottom: 10px;
}
.table-wrapper {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    color:#4a4a4a;
}

th,
td {
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f5f5f5;
    font-weight: 600;
}

td {
    border-bottom: 1px solid #e4e4e4;
}

@media (max-width: 600px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
    display: block;
    }
    tbody tr{
        border: 1px solid #bcbcbc;
        margin: 10px 0px;
        padding: 5px;
        border-radius: 10px;
        background: #fff;
    }
    th,
    td {
        text-align: left;
        padding: 4px;
    }
    th {
    position: absolute;
    top: -9999px;
    left: -9999px;
    }

    td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: left;
    }

    td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    font-weight: 600;
    }
}