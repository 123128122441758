@import url('http://fonts.googleapis.com/css?family=Raleway:400,700');
body {
	background: linear-gradient(90deg, #F7F7F7, #d7d7d7);
}
#register .MuiFormLabel-asterisk {
    color:red
}
.container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.screen {
    position: relative;
    height: 600px;
    overflow: hidden;
    max-width: 360px;
    background-color: #fff;
    border: 2px solid #7294c3;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0px 0px 9px #a6a6a6;
}

.screen__content {
	z-index: 1;
	position: relative;
	height: 100%;
}

.screen__background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);
}


.closeBottom{
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    color: red !important;
    font-size: 16px !important;
    margin: 0px !important;
}
.login {
	padding: 30px 30px 5px;
    display: grid;
}

.login__field {
	padding: 20px 0px;
	position: relative;
}

.login__icon {
	position: absolute;
	top: 30px;
	color: #3d4bac;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 100%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.login__submit {
	background: #ee7127 !important;
	font-size: 14px !important;
	margin-top: 30px !important;
	padding: 16px 20px !important;
	border-radius: 26px !important;
	border: 1px solid #ee7127 !important;
	text-transform: uppercase !important;
	font-weight: 700 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	color: #fff !important;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #ee7127;
}

.social-login {
	height: 140px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #325c94;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
	transform: scale(1.5);	
}
.imgLogo{
	width: 157px;
    display: flex;
    justify-self: center;
}
.MuiAlert-outlinedError{
    background: #fff !important;
    border-radius: 20px !important;
    margin-top: 15px;
}